import React from 'react'

import { Box, Container, H1, H2 } from '@allied-solutions/affinity'

import { ContactInfo, FullScreenLayout } from '../components'

const SomethingWentWrong = () => {
  return (
    <FullScreenLayout>
      <Container px={8} as="main">
        <Box mt={['100px', null, 0]} mx="auto" textAlign="center">
          <H1 className="h5" typeStyle="h5">
            Something went wrong...
          </H1>
          <H2>
            Contact us and we’ll
            <br /> help you out.
          </H2>
          <ContactInfo />
        </Box>
      </Container>
    </FullScreenLayout>
  )
}

export default SomethingWentWrong
